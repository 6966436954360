import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConnectUserPermission } from '@constants/connect-user.constant';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userPermissions: BehaviorSubject<Array<ConnectUserPermission>> = new BehaviorSubject<
    Array<ConnectUserPermission>
  >([]);

  public userPermissions$: Observable<Array<ConnectUserPermission>> =
    this.userPermissions.asObservable();

  public loadPermissions(permissions: Array<ConnectUserPermission>): void {
    this.userPermissions.next(permissions);
  }

  public hasPermissions(permissions: Array<ConnectUserPermission>): boolean {
    return this.userPermissions
      .getValue()
      .some((permission: ConnectUserPermission) => permissions.includes(permission));
  }
}
