export const environment = {
  production: false,
  environmentName: 'development',
  enabledServiceWorker: true,
  firebase: {
    apiKey: 'AIzaSyDKa4HEjLgMtmbHycKIpJplPVO9R7MgT2I',
    authDomain: 'valhalla-connect-dev.firebaseapp.com',
    projectId: 'valhalla-connect-dev',
    storageBucket: 'valhalla-connect-dev.appspot.com',
    messagingSenderId: '779747499829',
    appId: '1:779747499829:web:cd8a435dcbb3788381b67c',
  },
  cargamos: {
    apiUrl: 'https://dev.services.connect.cargamos.com',
    apiKey: 'AIzaSyAu1LNHvn-V8DKPA46D8Z6AN3bhbgbu34M',
  },
  googleMaps: {
    apiKey: 'AIzaSyAu1LNHvn-V8DKPA46D8Z6AN3bhbgbu34M',
    searchLink: 'https://www.google.com/maps/search/?api=1',
  },
  externalUrls: {
    cookiesLegalDocument:
      'https://legal.cargamos.com/documento/aviso-de-privacidad-general/#uso-de-cookies',
  },
  sentry: {
    enabled: false,
    apiKey: 'https://6f77a2c6e9fc4c8e81382080c8f2c703@o474795.ingest.sentry.io/4505165771571200',
  },
  statics: {
    csvCreateCoverageZone:
      'gs://valhalla-connect-dev.appspot.com/frontend-static-files/create_coverage_zone_template.csv',
    csvPostalCodesCoverage:
      'gs://valhalla-connect-dev.appspot.com/frontend-static-files/csv_coverage_template.csv',
  },
};
